//importing axios library
import { type } from '@testing-library/user-event/dist/type';
import axios from 'axios';

const baseurl = 'https://backend.lumyn.ai/';


//creating a class for the AI service
class service {    
    static async TextExtract(formData) {
        try {
            const response = await axios.post('https://xrsby7hegb.us-east-1.awsapprunner.com/text_extraction/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return error.response.data;
            } else {
                console.log(error);
            }
        }
    }
}


//exporting the class
export default service;
